import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModGraph from "../../../components/Chronology/Modules/ModGraph";

export const frontmatter = {
  title: "Día 44",
  week: "Semana 7",
  month: "abr",
  day: "26",
  monthNumber: "04",
  date: "2020-04-26",
  path: "/cronologia/semana-07#dia-26-abr",
};
const Day44 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModText>
          <strong>Nota:</strong> Debido a los contínuos cambios de criterio
          adoptados por mscbs.gob.es en los útimos días, los datos mostrados y
          difundidos no van a coincidir con el nuevo criterio oficial. De
          momento el criterio adoptado es el de mantener el criterio inicial
          para facilitar la detección de tendencias.
        </ModText>
        <ModDataEu fecha={"2020-04-26"} />
        <ModLethalityEu fecha={"2020-04-26"} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 2.790 nuevos positivos y 288 personas fallecidas.
          Permanecen hospitalizadas 80.150 personas, 1.634 más que el día
          anterior, y 3.024 reciben el alta. El número de casos ingresados en la
          UCI asciende a 7.764, lo que supone un aumento de 8 personas.
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de mortalidad
          es del 10,2 % y la tasa de recuperados del 43,6 %.
        </ModText>
        <ModGraph
          name="26-04-analisis-cb-cantabria"
          alt="analisis cantabria"
        />
        <ModText>
          Fuente de datos -{" "}
          <a
            href="https://www.scsalud.es/coronavirus"
            alt="link a salud cantabria"
          >
            Gobierno de Cantabria
          </a>
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day44;
